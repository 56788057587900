.dtk-login-button {
  background-color: #d5dbe3;
  color: #1d2037;
  padding: 2px 3px 2px 3px;
  font-size: 1.2rem;
  border: 2px solid #e5ebf3;
}

.dtk-login-button:hover {
  background-color: #e5ebf3;
}
