.dtk-select {
  width: 200px;
  position: relative;
  font-family: Arial;
}

.dtk-select-selected {
  color: #e8e8e8;
  background-color: #1446a0;
  padding: 2px;
}

.dtk-select-selected:after {
  position: absolute;
  content: '';
  top: 7px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.dtk-select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 3px;
}

.dtk-select-items div,
.select-selected {
  color: #e8e8e8;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

.dtk-select-items {
  position: absolute;
  background-color: #1446a0;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.dtk-select-hide {
  display: none;
}

.dtk-select-items div:hover,
.dtk-same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
