.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1446a0;
  height: 56px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo a {
  color: #e8e8e8;
  text-decoration: none;
  font-size: 1.5rem;
}

.toolbar__logo a:hover {
  color: #d5dbe3;
}

.toolbar__spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: #e8e8e8;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #d5dbe3;
}
