html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

.dtk-modalbox-hidden {
  display: none;
}

.dtk-modalbox {
  position: fixed;
  top: 10%;
  left: 25%;
  right: 25%;
  margin: 5px;
  min-width: 500px;
  z-index: 100;
  background: #d5dbe3;
  border: 3px solid #1d2037;
  border-radius: 8px;
}

.dtk-modalbox.wrong {
  -webkit-animation: shake 0.5s;
          animation: shake 0.5s;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

.dtk-modalcontent {
}

.dtk-modaltitle {
  color: #e8e8e8;
  background-color: #1446a0;
  font-size: 1.2rem;
  height: 2rem;
  padding: 3px;
}

.dtk-modaltitle span {
  font-family: norms, sans-serif;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
}

.dtk-modalbody {
  padding: 1.5rem 2rem 1.5rem 2rem;
}

.dtk-modalform ul {
  list-style-type: none;
  display: block;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.dtk-modalform li {
  position: relative;
  margin-bottom: 16px;
  text-align: left;
  display: list-item;
}

.dtk-modalform label {
  display: block;
  color: #1d2037;
  margin-bottom: 0.2rem;
  text-align: left;
  cursor: default;
  font-family: norms, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
}

.dtk-modalform input {
  font-size: 1rem;
  background-color: #e8e8e8;
  text-decoration: none;
  border: 1px solid;
  border-color: #1d2037;
  border-radius: 3px;
  padding: 11px 8px 8px;
  width: 100%;
  box-sizing: border-box;
}

.dtk-modalform input:focus {
  border-color: #1446a0;
}

.dtk-button-action {
  text-decoration: none;
  margin-bottom: 0px;
  margin-top: 8px;
  background-color: #1446a0;
  color: #e8e8e8;
  width: 100%;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
  padding-top: 16px;
  padding-bottom: 14px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  outline: none;
  cursor: pointer;
  overflow: visible;
  font-size: 1.5rem;
  border: 2px solid #1d2037;
  border-radius: 4px;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.dtk-button-action:hover {
  background-color: #2456b0;
}

.dtk-button-altaction {
  background: none;
  color: #1d2037;
  padding: 0;
  font: inherit;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #1d2037;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.dtk-button-altaction:hover {
  color: #3d4057;
  border-bottom: 1px solid #3d4057;
}

.blog p {
  padding-bottom: 12px;
}

.blog ol {
  list-style: decimal;
  list-style-position: outside;
}

.blog ol li {
  margin: 0 0 0 20px;
}

.blog dt {
  font-weight: bold;
  text-decoration: underline;
}

.blog dd {
  margin: 0;
  padding: 0 0 0.5em 0;
}

.dtk-select {
  width: 200px;
  position: relative;
  font-family: Arial;
}

.dtk-select-selected {
  color: #e8e8e8;
  background-color: #1446a0;
  padding: 2px;
}

.dtk-select-selected:after {
  position: absolute;
  content: '';
  top: 7px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.dtk-select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 3px;
}

.dtk-select-items div,
.select-selected {
  color: #e8e8e8;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

.dtk-select-items {
  position: absolute;
  background-color: #1446a0;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.dtk-select-hide {
  display: none;
}

.dtk-select-items div:hover,
.dtk-same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.modal_backdrop_visible {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.modal_backdrop_hidden {
  display: none;
}

.dtk-login-button {
  background-color: #d5dbe3;
  color: #1d2037;
  padding: 2px 3px 2px 3px;
  font-size: 1.2rem;
  border: 2px solid #e5ebf3;
}

.dtk-login-button:hover {
  background-color: #e5ebf3;
}

.toggle-button {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 22px;
  width: 26px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 26px;
  height: 2px;
  background: #e8e8e8;
}

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1446a0;
  height: 56px;
}

.toolbar__navigation {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo a {
  color: #e8e8e8;
  text-decoration: none;
  font-size: 1.5rem;
}

.toolbar__logo a:hover {
  color: #d5dbe3;
}

.toolbar__spacer {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: #e8e8e8;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #d5dbe3;
}

.sidedrawer_backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.side-drawer {
  height: 100%;
  background: #d5dbe3;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.side-drawer.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.side-drawer hr {
  margin: 5px 0 5px 0;
  border-color: #1336a0;
}

.side-drawer-header {
  height: 50px;
  color: #1336a0;
  font-size: 1.5rem;
  padding: 18px 0px 0px 20px;
}

.side-drawer-section {
}

.side-drawer-section h1 {
  padding: 18px 0 0px 20px;
  font-size: 1.2rem;
  margin: 0;
  color: #1336a0;
}

.side-drawer-section ul {
  height: 100%;
  list-style: none;
  padding: 0 0 4px 20px;
}

.side-drawer-section li {
  margin: 0.2rem 0 0 0.8rem;
}

.side-drawer-section a {
  text-decoration: none;
  color: #1446a0;
  font-size: 1.2rem;
}

.side-drawer-section a:hover,
.side-drawer-section a:active {
  color: white;
}

