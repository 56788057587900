.side-drawer {
  height: 100%;
  background: #d5dbe3;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.2s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer hr {
  margin: 5px 0 5px 0;
  border-color: #1336a0;
}

.side-drawer-header {
  height: 50px;
  color: #1336a0;
  font-size: 1.5rem;
  padding: 18px 0px 0px 20px;
}

.side-drawer-section {
}

.side-drawer-section h1 {
  padding: 18px 0 0px 20px;
  font-size: 1.2rem;
  margin: 0;
  color: #1336a0;
}

.side-drawer-section ul {
  height: 100%;
  list-style: none;
  padding: 0 0 4px 20px;
}

.side-drawer-section li {
  margin: 0.2rem 0 0 0.8rem;
}

.side-drawer-section a {
  text-decoration: none;
  color: #1446a0;
  font-size: 1.2rem;
}

.side-drawer-section a:hover,
.side-drawer-section a:active {
  color: white;
}
