html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

.dtk-modalbox-hidden {
  display: none;
}

.dtk-modalbox {
  position: fixed;
  top: 10%;
  left: 25%;
  right: 25%;
  margin: 5px;
  min-width: 500px;
  z-index: 100;
  background: #d5dbe3;
  border: 3px solid #1d2037;
  border-radius: 8px;
}

.dtk-modalbox.wrong {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.dtk-modalcontent {
}

.dtk-modaltitle {
  color: #e8e8e8;
  background-color: #1446a0;
  font-size: 1.2rem;
  height: 2rem;
  padding: 3px;
}

.dtk-modaltitle span {
  font-family: norms, sans-serif;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
}

.dtk-modalbody {
  padding: 1.5rem 2rem 1.5rem 2rem;
}

.dtk-modalform ul {
  list-style-type: none;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.dtk-modalform li {
  position: relative;
  margin-bottom: 16px;
  text-align: left;
  display: list-item;
}

.dtk-modalform label {
  display: block;
  color: #1d2037;
  margin-bottom: 0.2rem;
  text-align: left;
  cursor: default;
  font-family: norms, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
}

.dtk-modalform input {
  font-size: 1rem;
  background-color: #e8e8e8;
  text-decoration: none;
  border: 1px solid;
  border-color: #1d2037;
  border-radius: 3px;
  padding: 11px 8px 8px;
  width: 100%;
  box-sizing: border-box;
}

.dtk-modalform input:focus {
  border-color: #1446a0;
}

.dtk-button-action {
  text-decoration: none;
  margin-bottom: 0px;
  margin-top: 8px;
  background-color: #1446a0;
  color: #e8e8e8;
  width: 100%;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
  padding-top: 16px;
  padding-bottom: 14px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  outline: none;
  cursor: pointer;
  overflow: visible;
  font-size: 1.5rem;
  border: 2px solid #1d2037;
  border-radius: 4px;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.dtk-button-action:hover {
  background-color: #2456b0;
}

.dtk-button-altaction {
  background: none;
  color: #1d2037;
  padding: 0;
  font: inherit;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #1d2037;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.dtk-button-altaction:hover {
  color: #3d4057;
  border-bottom: 1px solid #3d4057;
}
