.blog p {
  padding-bottom: 12px;
}

.blog ol {
  list-style: decimal;
  list-style-position: outside;
}

.blog ol li {
  margin: 0 0 0 20px;
}

.blog dt {
  font-weight: bold;
  text-decoration: underline;
}

.blog dd {
  margin: 0;
  padding: 0 0 0.5em 0;
}
